import React from "react"

export default function GradientButton({ children, className }) {
  return <div
    className={[`w-full sm:w-auto p-0.5 rounded-3xl
      bg-gradient-to-r from-ka-cyan via-ka-blue to-ka-purple
      inline-flex items-center`, className].join(' ')}>
    <div
      className="w-full h-full rounded-3xl
        bg-gradient-to-l from-ka-cyan via-ka-blue to-ka-purple
        flex items-center justify-center
        text-center font-medium uppercase text-sm">
      {children}
    </div>
  </div>
}
