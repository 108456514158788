import React, { useMemo } from "react";
import { useContentfulFNCSData } from "../hooks/contentful";
import { VirtuosoTable } from "./VirtuosoTable";
import Loading from "./loading";

const mixColors = (base, added) => {
  var mix = [];
  mix[3] = 1 - (1 - added[3]) * (1 - base[3]); // alpha
  mix[0] = Math.round(
    (added[0] * added[3]) / mix[3] +
      (base[0] * base[3] * (1 - added[3])) / mix[3]
  ); // red
  mix[1] = Math.round(
    (added[1] * added[3]) / mix[3] +
      (base[1] * base[3] * (1 - added[3])) / mix[3]
  ); // green
  mix[2] = Math.round(
    (added[2] * added[3]) / mix[3] +
      (base[2] * base[3] * (1 - added[3])) / mix[3]
  ); // blue
  return `rgba(${mix.join(",")})`;
};

export const FncsTable = ({ region, filterText }) => {
  const [loading, data] = useContentfulFNCSData(region);
  const header = data ? data[0] : [];
  const filterColumnId = header.indexOf("Names");

  // Generating sizes
  const [sizes, maxVals] = useMemo(() => {
    const lengthArr = new Array(data ? data[0].length : 0).fill(0);
    const maxVals = new Array(data ? data[0].length : 0).fill(0);
    const tableMaxSize = 1200;
    const namesFieldLen = 400;
    const remainingSize =
      tableMaxSize - namesFieldLen - (lengthArr.length - 1) * 40;
    for (const idx in data) {
      const row = data[idx];
      // exluding header
      // if (idx > 0) {
      for (const colIdx in row) {
        const val = row[colIdx];
        if (val && val.length > lengthArr[colIdx]) {
          lengthArr[colIdx] = val.length;
        }
        if (parseInt(val) > maxVals[colIdx]) {
          maxVals[colIdx] = parseInt(val);
        }
      }
      // }
    }
    const excluded = lengthArr.slice();
    excluded.splice(filterColumnId, 1);
    const max = excluded.reduce((a, b) => a + b, 0);
    return [
      lengthArr.map((length, idx) =>
        idx === filterColumnId
          ? namesFieldLen
          : 40 + (length * remainingSize) / max
      ),
      maxVals,
    ];
  }, [data, filterColumnId]);

  const entries = data
    ? data
        .slice(1)
        .map((el, index) => ({ id: index, row: el }))
        .filter((el) =>
          el.row[filterColumnId]
            .toLowerCase()
            .includes(filterText.toLowerCase())
        )
    : [];

  const getStyle = (value, columnId, columnName) => {
    let bgColor = "transparent";
    let textColor = "white";
    if (
      columnName.toLowerCase() === "q1 points" ||
      columnName.toLowerCase() === "q2 points" ||
      columnName.toLowerCase() === "q3 points"
    ) {
      const numVal = parseFloat(value);
      if (numVal) {
        bgColor = mixColors(
          [255, 255, 255, 1],
          [7, 203, 231, 0.2 + (0.8 * parseFloat(value)) / maxVals[columnId]]
        );
        textColor = "#0E1041";
      }
      // else {
      //   bgColor = 'rgba(7, 159, 87, 0.5)'
      //   textColor = "#0E1041";
      // }
    }
    if (columnName.toLowerCase() === "series points") {
      const numVal = parseFloat(value);
      if (numVal) {
        bgColor = mixColors(
          [255, 255, 255, 1],
          [149, 82, 205, 0.2 + (0.8 * parseFloat(value)) / maxVals[columnId]]
        );
        textColor = "#0E1041";
      }
    }
    if (columnName.toLowerCase() === "status") {
      if (value.toLowerCase() === "grand finals") {
        bgColor = "#1F3CC5";
      } else if (value.toLowerCase() === "qualifying") {
        bgColor = mixColors([255, 255, 255, 1], [31, 60, 197, 0.5]);
        textColor = "#0E1041";
      }
    }
    return {
      bgColor,
      textColor,
    };
  };

  return data && data.length > 1 ? (
    <div className="w-full mt-4">
      <VirtuosoTable
        header={header}
        entries={entries}
        sizes={sizes}
        getStyle={getStyle}
      />
    </div>
  ) : (
    <div
      className="w-full h-32 flex items-center justify-center"
      style={{
        minHeight: "600px",
        height: "60vh",
      }}
    >
      {loading ? <Loading /> : <h3>No data available at the moment</h3>}
    </div>
  );
};
