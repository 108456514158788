import { useState, useEffect } from "react";
import axios from "axios";

const PROD_CONFIG = {
  baseURL:
    "https://cdn.contentful.com/spaces/mzarhj7elvy8/environments/master/",
  headers: {
    Authorization: "Bearer IIz2QWlhRFfXUim7JklxqCugwIuL9aWJDuZANYeeQpM",
  },
}

const PREVIEW_CONFIG = {
  baseURL:
    "https://preview.contentful.com/spaces/mzarhj7elvy8/environments/master/",
  headers: {
    Authorization: "Bearer LSwWU9H_meeSbkwRFwx0gaLWB43Fmyqhepipce6SZaI",
  },
}

const contentfulClient = axios.create(PREVIEW_CONFIG);

export function useContentfulFNCSData(region) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await contentfulClient.get(
          `entries/fncsRegionLeaderboardFull_${region.toUpperCase()}`
        );
        setData(response["data"]["fields"]["data"]);
        setLoading(false);
      } catch (error) {
        setData(null)
        setLoading(false);
      }
    }

    if (region !== "") {
      fetchData();
    }
  }, [region]);

  return [loading, data];
}
