import React from "react";

import brandPattern from "../assets/brand-pattern.png";
import "./styles.scss";
import { Virtuoso } from "react-virtuoso";
export const VirtuosoTable = ({ header, entries, sizes, getStyle }) => {
  const totalSize = sizes.reduce((a, b) => a + b, 0);
  return (
    <div className="w-full virtable">
      <div
        className="flex flex-row py-1 mb-2"
        style={{
          backgroundColor: "#26285D",
          borderRadius: "6px",
          minWidth: totalSize / 1.5,
        }}
      >
        {header.map((el, idx) => (
          <div
            className="long-text px-2 sm:px-4"
            style={{
              minWidth: sizes[idx] / 2,
              width: sizes[idx],
              color: "rgba(255,255,255,0.5)",
            }}
          >
            {el}
          </div>
        ))}
      </div>
      <Virtuoso
        style={{
          minHeight: "600px",
          height: "60vh",
          minWidth: totalSize / 1.5,
        }}
        data={entries}
        itemContent={(index, user) => {
          return (
            <div
              className="flex flex-row items-center vt-row"
              style={{
                blend: "passthrough",
                borderRadius: "6px",
                backgroundColor: index % 2 === 0 ? "#26285D" : "#0E1041",
                // backgroundImage: `url(${brandPattern})`,
                backgroundPosition: "start",
                backgroundSize: "contain",
              }}
            >
              {user.row.map((el, idx) => {
                const { bgColor, textColor } = getStyle(el, idx, header[idx]);
                return (
                  <div
                    className={`h-full py-4 flex items-center justify-center rounded ${"px-1 sm:px-3"}`}
                    style={{
                      minWidth: sizes[idx] / 2,
                      width: sizes[idx],
                    }}
                  >
                    <div
                      className="px-1 py-1 w-full text-center rounded overflow-hidden"
                      style={{
                        color: textColor,
                        backgroundColor: bgColor,
                      }}
                    >
                      <div className="overflow-hidden">
                        <div className="long-text">{el}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
};
